:root {
  --bg-color: white;
  --text-color: black;
}

[data-theme="dark"] {
  --bg-color: #121212;
  --text-color: #fff;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

#map {
  width: 100%;
  height: 500px;
}
/*# sourceMappingURL=index.ddccd2ba.css.map */
